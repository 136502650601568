@import url("https://fonts.googleapis.com/css2?family=Capriola&family=Noto+Serif+Display:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Capriola&family=Cedarville+Cursive&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    @apply border border-black rounded-xl px-5 py-1;
  }
}
::selection {
  @apply bg-orange-700 text-white;
}

::-webkit-scrollbar {
  width: 0.75rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  /* background: rgba(194, 64, 12, 0.745); */
  background: rgb(194 65 12);
}

::-webkit-scrollbar-thumb:hover {
  /* background: rgb(194 65 12); */
  background: rgb(144, 41, 0);
}
